import { EDIT } from '@common/const';
import { IRoute } from '@common/models';

export const OPEN_POSITIONS = Object.freeze({
  root: 'open-positions',
  positionId: 'positionId',
});
export const CANDIDATES_STATUS = Object.freeze({
  root: 'candidates-status',
  candidateId: 'candidateId',
});
export const RESOURCES = Object.freeze({
  root: 'resources',
  resourceId: 'resourceId',
  edit: EDIT,
});
export const ALLOCATED_RESOURCES = Object.freeze({
  root: 'allocated-resources',
});
export const TIME_SUBMISSION = Object.freeze({
  root: 'time-submission',
});

export const INVOICES = Object.freeze({
  root: 'invoices',
});

export const routes: IRoute[] = [
  {
    path: OPEN_POSITIONS.root,
    name: 'OPEN_POSITIONS',
    disabled: false,
  },
  {
    path: CANDIDATES_STATUS.root,
    name: 'CANDIDATES_STATUS',
    disabled: false,
  },
  {
    path: RESOURCES.root,
    name: 'RESOURCES',
    disabled: false,
  },
  {
    path: ALLOCATED_RESOURCES.root,
    name: 'ALLOCATED_RESOURCES',
    disabled: false,
  },
  {
    path: TIME_SUBMISSION.root,
    name: 'TIME_SUBMISSION',
    disabled: false,
  },
  // Task number 95018 (Hide this menu to prevent the ability to submit an Invoice through this page)
  /*  {
    path: INVOICES.root,
    name: 'INVOICES',
    disabled: false,
  },*/
];
