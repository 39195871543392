/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';

import { BaseFileDto } from '../models/base-file-dto';
import { BaseNameDto } from '../models/base-name-dto';
import { ExportTimeSheetCsvRequest } from '../models/export-time-sheet-csv-request';
import { ImportTimeSheetCsvRequest } from '../models/import-time-sheet-csv-request';
import { PartnerBillDetailsResponse } from '../models/partner-bill-details-response';
import { ReportingHoursDto } from '../models/reporting-hours-dto';
import { TimeSheetDto } from '../models/time-sheet-dto';
import { TimeSheetsRequest } from '../models/time-sheets-request';
import { UpdateBillAdditionalCostRequest } from '../models/update-bill-additional-cost-request';
import { UpdateCostAdjustmentResponse } from '../models/update-cost-adjustment-response';
import { UpdateHoursRequest } from '../models/update-hours-request';
import { UpdatePartnerBillDetailsRequest } from '../models/update-partner-bill-details-request';
import { UpdateTimeSheetCommentRequest } from '../models/update-time-sheet-comment-request';
import { UpdateTimeSheetCostAdjustmentRequest } from '../models/update-time-sheet-cost-adjustment-request';

@Injectable({ providedIn: 'root' })
export class ReportingHoursService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `reportingHoursPost()` */
  static readonly ReportingHoursPostPath = '/reporting-hours';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `reportingHoursPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  reportingHoursPost$Plain$Response(
    params?: {
      body?: TimeSheetsRequest
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<ReportingHoursDto>> {
    const rb = new RequestBuilder(this.rootUrl, ReportingHoursService.ReportingHoursPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: 'text/plain', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ReportingHoursDto>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `reportingHoursPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  reportingHoursPost$Plain(
    params?: {
      body?: TimeSheetsRequest
    },
    context?: HttpContext
  ): Observable<ReportingHoursDto> {
    return this.reportingHoursPost$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<ReportingHoursDto>): ReportingHoursDto => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `reportingHoursPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  reportingHoursPost$Json$Response(
    params?: {
      body?: TimeSheetsRequest
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<ReportingHoursDto>> {
    const rb = new RequestBuilder(this.rootUrl, ReportingHoursService.ReportingHoursPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'text/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ReportingHoursDto>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `reportingHoursPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  reportingHoursPost$Json(
    params?: {
      body?: TimeSheetsRequest
    },
    context?: HttpContext
  ): Observable<ReportingHoursDto> {
    return this.reportingHoursPost$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<ReportingHoursDto>): ReportingHoursDto => r.body)
    );
  }

  /** Path part for operation `reportingClientsOrProjectsNamesPost()` */
  static readonly ReportingClientsOrProjectsNamesPostPath = '/reporting-clients-or-projects-names';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `reportingClientsOrProjectsNamesPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  reportingClientsOrProjectsNamesPost$Plain$Response(
    params?: {
      body?: TimeSheetsRequest
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<BaseNameDto>>> {
    const rb = new RequestBuilder(this.rootUrl, ReportingHoursService.ReportingClientsOrProjectsNamesPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: 'text/plain', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<BaseNameDto>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `reportingClientsOrProjectsNamesPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  reportingClientsOrProjectsNamesPost$Plain(
    params?: {
      body?: TimeSheetsRequest
    },
    context?: HttpContext
  ): Observable<Array<BaseNameDto>> {
    return this.reportingClientsOrProjectsNamesPost$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<BaseNameDto>>): Array<BaseNameDto> => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `reportingClientsOrProjectsNamesPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  reportingClientsOrProjectsNamesPost$Json$Response(
    params?: {
      body?: TimeSheetsRequest
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<BaseNameDto>>> {
    const rb = new RequestBuilder(this.rootUrl, ReportingHoursService.ReportingClientsOrProjectsNamesPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'text/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<BaseNameDto>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `reportingClientsOrProjectsNamesPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  reportingClientsOrProjectsNamesPost$Json(
    params?: {
      body?: TimeSheetsRequest
    },
    context?: HttpContext
  ): Observable<Array<BaseNameDto>> {
    return this.reportingClientsOrProjectsNamesPost$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<BaseNameDto>>): Array<BaseNameDto> => r.body)
    );
  }

  /** Path part for operation `sendReportingHoursPost()` */
  static readonly SendReportingHoursPostPath = '/send-reporting-hours';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `sendReportingHoursPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  sendReportingHoursPost$Plain$Response(
    params?: {
      body?: TimeSheetsRequest
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<ReportingHoursDto>> {
    const rb = new RequestBuilder(this.rootUrl, ReportingHoursService.SendReportingHoursPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: 'text/plain', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ReportingHoursDto>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `sendReportingHoursPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  sendReportingHoursPost$Plain(
    params?: {
      body?: TimeSheetsRequest
    },
    context?: HttpContext
  ): Observable<ReportingHoursDto> {
    return this.sendReportingHoursPost$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<ReportingHoursDto>): ReportingHoursDto => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `sendReportingHoursPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  sendReportingHoursPost$Json$Response(
    params?: {
      body?: TimeSheetsRequest
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<ReportingHoursDto>> {
    const rb = new RequestBuilder(this.rootUrl, ReportingHoursService.SendReportingHoursPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'text/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ReportingHoursDto>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `sendReportingHoursPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  sendReportingHoursPost$Json(
    params?: {
      body?: TimeSheetsRequest
    },
    context?: HttpContext
  ): Observable<ReportingHoursDto> {
    return this.sendReportingHoursPost$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<ReportingHoursDto>): ReportingHoursDto => r.body)
    );
  }

  /** Path part for operation `updateCommentPost()` */
  static readonly UpdateCommentPostPath = '/update-comment';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateCommentPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  updateCommentPost$Plain$Response(
    params?: {
      body?: UpdateTimeSheetCommentRequest
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<TimeSheetDto>> {
    const rb = new RequestBuilder(this.rootUrl, ReportingHoursService.UpdateCommentPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: 'text/plain', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<TimeSheetDto>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateCommentPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  updateCommentPost$Plain(
    params?: {
      body?: UpdateTimeSheetCommentRequest
    },
    context?: HttpContext
  ): Observable<TimeSheetDto> {
    return this.updateCommentPost$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<TimeSheetDto>): TimeSheetDto => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateCommentPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  updateCommentPost$Json$Response(
    params?: {
      body?: UpdateTimeSheetCommentRequest
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<TimeSheetDto>> {
    const rb = new RequestBuilder(this.rootUrl, ReportingHoursService.UpdateCommentPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'text/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<TimeSheetDto>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateCommentPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  updateCommentPost$Json(
    params?: {
      body?: UpdateTimeSheetCommentRequest
    },
    context?: HttpContext
  ): Observable<TimeSheetDto> {
    return this.updateCommentPost$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<TimeSheetDto>): TimeSheetDto => r.body)
    );
  }

  /** Path part for operation `updateHoursPost()` */
  static readonly UpdateHoursPostPath = '/update-hours';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateHoursPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  updateHoursPost$Plain$Response(
    params?: {
      body?: UpdateHoursRequest
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<TimeSheetDto>> {
    const rb = new RequestBuilder(this.rootUrl, ReportingHoursService.UpdateHoursPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: 'text/plain', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<TimeSheetDto>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateHoursPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  updateHoursPost$Plain(
    params?: {
      body?: UpdateHoursRequest
    },
    context?: HttpContext
  ): Observable<TimeSheetDto> {
    return this.updateHoursPost$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<TimeSheetDto>): TimeSheetDto => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateHoursPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  updateHoursPost$Json$Response(
    params?: {
      body?: UpdateHoursRequest
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<TimeSheetDto>> {
    const rb = new RequestBuilder(this.rootUrl, ReportingHoursService.UpdateHoursPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'text/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<TimeSheetDto>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateHoursPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  updateHoursPost$Json(
    params?: {
      body?: UpdateHoursRequest
    },
    context?: HttpContext
  ): Observable<TimeSheetDto> {
    return this.updateHoursPost$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<TimeSheetDto>): TimeSheetDto => r.body)
    );
  }

  /** Path part for operation `importTimesheetsPost()` */
  static readonly ImportTimesheetsPostPath = '/import-timesheets';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `importTimesheetsPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  importTimesheetsPost$Plain$Response(
    params?: {
      body?: ImportTimeSheetCsvRequest
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<ReportingHoursDto>> {
    const rb = new RequestBuilder(this.rootUrl, ReportingHoursService.ImportTimesheetsPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: 'text/plain', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ReportingHoursDto>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `importTimesheetsPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  importTimesheetsPost$Plain(
    params?: {
      body?: ImportTimeSheetCsvRequest
    },
    context?: HttpContext
  ): Observable<ReportingHoursDto> {
    return this.importTimesheetsPost$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<ReportingHoursDto>): ReportingHoursDto => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `importTimesheetsPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  importTimesheetsPost$Json$Response(
    params?: {
      body?: ImportTimeSheetCsvRequest
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<ReportingHoursDto>> {
    const rb = new RequestBuilder(this.rootUrl, ReportingHoursService.ImportTimesheetsPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'text/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ReportingHoursDto>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `importTimesheetsPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  importTimesheetsPost$Json(
    params?: {
      body?: ImportTimeSheetCsvRequest
    },
    context?: HttpContext
  ): Observable<ReportingHoursDto> {
    return this.importTimesheetsPost$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<ReportingHoursDto>): ReportingHoursDto => r.body)
    );
  }

  /** Path part for operation `exportTimesheetsPost()` */
  static readonly ExportTimesheetsPostPath = '/export-timesheets';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `exportTimesheetsPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  exportTimesheetsPost$Plain$Response(
    params?: {
      body?: ExportTimeSheetCsvRequest
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<BaseFileDto>> {
    const rb = new RequestBuilder(this.rootUrl, ReportingHoursService.ExportTimesheetsPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: 'text/plain', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BaseFileDto>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `exportTimesheetsPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  exportTimesheetsPost$Plain(
    params?: {
      body?: ExportTimeSheetCsvRequest
    },
    context?: HttpContext
  ): Observable<BaseFileDto> {
    return this.exportTimesheetsPost$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<BaseFileDto>): BaseFileDto => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `exportTimesheetsPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  exportTimesheetsPost$Json$Response(
    params?: {
      body?: ExportTimeSheetCsvRequest
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<BaseFileDto>> {
    const rb = new RequestBuilder(this.rootUrl, ReportingHoursService.ExportTimesheetsPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'text/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BaseFileDto>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `exportTimesheetsPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  exportTimesheetsPost$Json(
    params?: {
      body?: ExportTimeSheetCsvRequest
    },
    context?: HttpContext
  ): Observable<BaseFileDto> {
    return this.exportTimesheetsPost$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<BaseFileDto>): BaseFileDto => r.body)
    );
  }

  /** Path part for operation `updateAdditionalCostPost()` */
  static readonly UpdateAdditionalCostPostPath = '/update-additional-cost';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateAdditionalCostPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  updateAdditionalCostPost$Plain$Response(
    params?: {
      body?: UpdateBillAdditionalCostRequest
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<number>> {
    const rb = new RequestBuilder(this.rootUrl, ReportingHoursService.UpdateAdditionalCostPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: 'text/plain', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: parseFloat(String((r as HttpResponse<any>).body)) }) as StrictHttpResponse<number>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateAdditionalCostPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  updateAdditionalCostPost$Plain(
    params?: {
      body?: UpdateBillAdditionalCostRequest
    },
    context?: HttpContext
  ): Observable<number> {
    return this.updateAdditionalCostPost$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<number>): number => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateAdditionalCostPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  updateAdditionalCostPost$Json$Response(
    params?: {
      body?: UpdateBillAdditionalCostRequest
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<number>> {
    const rb = new RequestBuilder(this.rootUrl, ReportingHoursService.UpdateAdditionalCostPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'text/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: parseFloat(String((r as HttpResponse<any>).body)) }) as StrictHttpResponse<number>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateAdditionalCostPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  updateAdditionalCostPost$Json(
    params?: {
      body?: UpdateBillAdditionalCostRequest
    },
    context?: HttpContext
  ): Observable<number> {
    return this.updateAdditionalCostPost$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<number>): number => r.body)
    );
  }

  /** Path part for operation `updateCostAdjustmentPost()` */
  static readonly UpdateCostAdjustmentPostPath = '/update-cost-adjustment';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateCostAdjustmentPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  updateCostAdjustmentPost$Plain$Response(
    params?: {
      body?: UpdateTimeSheetCostAdjustmentRequest
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<UpdateCostAdjustmentResponse>> {
    const rb = new RequestBuilder(this.rootUrl, ReportingHoursService.UpdateCostAdjustmentPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: 'text/plain', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<UpdateCostAdjustmentResponse>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateCostAdjustmentPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  updateCostAdjustmentPost$Plain(
    params?: {
      body?: UpdateTimeSheetCostAdjustmentRequest
    },
    context?: HttpContext
  ): Observable<UpdateCostAdjustmentResponse> {
    return this.updateCostAdjustmentPost$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<UpdateCostAdjustmentResponse>): UpdateCostAdjustmentResponse => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateCostAdjustmentPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  updateCostAdjustmentPost$Json$Response(
    params?: {
      body?: UpdateTimeSheetCostAdjustmentRequest
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<UpdateCostAdjustmentResponse>> {
    const rb = new RequestBuilder(this.rootUrl, ReportingHoursService.UpdateCostAdjustmentPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'text/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<UpdateCostAdjustmentResponse>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateCostAdjustmentPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  updateCostAdjustmentPost$Json(
    params?: {
      body?: UpdateTimeSheetCostAdjustmentRequest
    },
    context?: HttpContext
  ): Observable<UpdateCostAdjustmentResponse> {
    return this.updateCostAdjustmentPost$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<UpdateCostAdjustmentResponse>): UpdateCostAdjustmentResponse => r.body)
    );
  }

  /** Path part for operation `invoiceFileGet()` */
  static readonly InvoiceFileGetPath = '/invoice-file';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `invoiceFileGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  invoiceFileGet$Plain$Response(
    params?: {
      id?: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<BaseFileDto>> {
    const rb = new RequestBuilder(this.rootUrl, ReportingHoursService.InvoiceFileGetPath, 'get');
    if (params) {
      rb.query('id', params.id, {"style":"form"});
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: 'text/plain', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BaseFileDto>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `invoiceFileGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  invoiceFileGet$Plain(
    params?: {
      id?: string;
    },
    context?: HttpContext
  ): Observable<BaseFileDto> {
    return this.invoiceFileGet$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<BaseFileDto>): BaseFileDto => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `invoiceFileGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  invoiceFileGet$Json$Response(
    params?: {
      id?: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<BaseFileDto>> {
    const rb = new RequestBuilder(this.rootUrl, ReportingHoursService.InvoiceFileGetPath, 'get');
    if (params) {
      rb.query('id', params.id, {"style":"form"});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'text/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BaseFileDto>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `invoiceFileGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  invoiceFileGet$Json(
    params?: {
      id?: string;
    },
    context?: HttpContext
  ): Observable<BaseFileDto> {
    return this.invoiceFileGet$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<BaseFileDto>): BaseFileDto => r.body)
    );
  }

  /** Path part for operation `updateInvoiceDetailsPost()` */
  static readonly UpdateInvoiceDetailsPostPath = '/update-invoice-details';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateInvoiceDetailsPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  updateInvoiceDetailsPost$Plain$Response(
    params?: {
      body?: UpdatePartnerBillDetailsRequest
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<PartnerBillDetailsResponse>> {
    const rb = new RequestBuilder(this.rootUrl, ReportingHoursService.UpdateInvoiceDetailsPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: 'text/plain', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PartnerBillDetailsResponse>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateInvoiceDetailsPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  updateInvoiceDetailsPost$Plain(
    params?: {
      body?: UpdatePartnerBillDetailsRequest
    },
    context?: HttpContext
  ): Observable<PartnerBillDetailsResponse> {
    return this.updateInvoiceDetailsPost$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<PartnerBillDetailsResponse>): PartnerBillDetailsResponse => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateInvoiceDetailsPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  updateInvoiceDetailsPost$Json$Response(
    params?: {
      body?: UpdatePartnerBillDetailsRequest
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<PartnerBillDetailsResponse>> {
    const rb = new RequestBuilder(this.rootUrl, ReportingHoursService.UpdateInvoiceDetailsPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'text/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PartnerBillDetailsResponse>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateInvoiceDetailsPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  updateInvoiceDetailsPost$Json(
    params?: {
      body?: UpdatePartnerBillDetailsRequest
    },
    context?: HttpContext
  ): Observable<PartnerBillDetailsResponse> {
    return this.updateInvoiceDetailsPost$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<PartnerBillDetailsResponse>): PartnerBillDetailsResponse => r.body)
    );
  }

}
