/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';

import { BaseRequestList } from '../models/base-request-list';
import { DocumentDto } from '../models/document-dto';

@Injectable({ providedIn: 'root' })
export class InvoicesService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `invoicesPost()` */
  static readonly InvoicesPostPath = '/invoices';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `invoicesPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  invoicesPost$Plain$Response(
    params?: {
      body?: BaseRequestList
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<DocumentDto>>> {
    const rb = new RequestBuilder(this.rootUrl, InvoicesService.InvoicesPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: 'text/plain', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<DocumentDto>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `invoicesPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  invoicesPost$Plain(
    params?: {
      body?: BaseRequestList
    },
    context?: HttpContext
  ): Observable<Array<DocumentDto>> {
    return this.invoicesPost$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<DocumentDto>>): Array<DocumentDto> => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `invoicesPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  invoicesPost$Json$Response(
    params?: {
      body?: BaseRequestList
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<DocumentDto>>> {
    const rb = new RequestBuilder(this.rootUrl, InvoicesService.InvoicesPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'text/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<DocumentDto>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `invoicesPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  invoicesPost$Json(
    params?: {
      body?: BaseRequestList
    },
    context?: HttpContext
  ): Observable<Array<DocumentDto>> {
    return this.invoicesPost$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<DocumentDto>>): Array<DocumentDto> => r.body)
    );
  }

  /** Path part for operation `downloadInvoicePost()` */
  static readonly DownloadInvoicePostPath = '/download-invoice';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `downloadInvoicePost$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  downloadInvoicePost$Plain$Response(
    params?: {
      invoiceId?: number;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<DocumentDto>> {
    const rb = new RequestBuilder(this.rootUrl, InvoicesService.DownloadInvoicePostPath, 'post');
    if (params) {
      rb.query('invoiceId', params.invoiceId, {"style":"form"});
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: 'text/plain', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<DocumentDto>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `downloadInvoicePost$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  downloadInvoicePost$Plain(
    params?: {
      invoiceId?: number;
    },
    context?: HttpContext
  ): Observable<DocumentDto> {
    return this.downloadInvoicePost$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<DocumentDto>): DocumentDto => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `downloadInvoicePost$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  downloadInvoicePost$Json$Response(
    params?: {
      invoiceId?: number;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<DocumentDto>> {
    const rb = new RequestBuilder(this.rootUrl, InvoicesService.DownloadInvoicePostPath, 'post');
    if (params) {
      rb.query('invoiceId', params.invoiceId, {"style":"form"});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'text/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<DocumentDto>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `downloadInvoicePost$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  downloadInvoicePost$Json(
    params?: {
      invoiceId?: number;
    },
    context?: HttpContext
  ): Observable<DocumentDto> {
    return this.downloadInvoicePost$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<DocumentDto>): DocumentDto => r.body)
    );
  }

  /** Path part for operation `uploadInvoicePost()` */
  static readonly UploadInvoicePostPath = '/upload-invoice';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `uploadInvoicePost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  uploadInvoicePost$Plain$Response(
    params?: {
      body?: DocumentDto
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<DocumentDto>> {
    const rb = new RequestBuilder(this.rootUrl, InvoicesService.UploadInvoicePostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: 'text/plain', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<DocumentDto>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `uploadInvoicePost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  uploadInvoicePost$Plain(
    params?: {
      body?: DocumentDto
    },
    context?: HttpContext
  ): Observable<DocumentDto> {
    return this.uploadInvoicePost$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<DocumentDto>): DocumentDto => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `uploadInvoicePost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  uploadInvoicePost$Json$Response(
    params?: {
      body?: DocumentDto
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<DocumentDto>> {
    const rb = new RequestBuilder(this.rootUrl, InvoicesService.UploadInvoicePostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'text/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<DocumentDto>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `uploadInvoicePost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  uploadInvoicePost$Json(
    params?: {
      body?: DocumentDto
    },
    context?: HttpContext
  ): Observable<DocumentDto> {
    return this.uploadInvoicePost$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<DocumentDto>): DocumentDto => r.body)
    );
  }

  /** Path part for operation `deleteInvoicePost()` */
  static readonly DeleteInvoicePostPath = '/delete-invoice';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteInvoicePost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  deleteInvoicePost$Plain$Response(
    params?: {
      body?: number
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<number>> {
    const rb = new RequestBuilder(this.rootUrl, InvoicesService.DeleteInvoicePostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: 'text/plain', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: parseFloat(String((r as HttpResponse<any>).body)) }) as StrictHttpResponse<number>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `deleteInvoicePost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  deleteInvoicePost$Plain(
    params?: {
      body?: number
    },
    context?: HttpContext
  ): Observable<number> {
    return this.deleteInvoicePost$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<number>): number => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteInvoicePost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  deleteInvoicePost$Json$Response(
    params?: {
      body?: number
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<number>> {
    const rb = new RequestBuilder(this.rootUrl, InvoicesService.DeleteInvoicePostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'text/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: parseFloat(String((r as HttpResponse<any>).body)) }) as StrictHttpResponse<number>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `deleteInvoicePost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  deleteInvoicePost$Json(
    params?: {
      body?: number
    },
    context?: HttpContext
  ): Observable<number> {
    return this.deleteInvoicePost$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<number>): number => r.body)
    );
  }

}
