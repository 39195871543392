/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';

import { IAccountsAllAccountsItems } from '../models/i-accounts-all-accounts-items';
import { IAllCountriesAllCountriesItems } from '../models/i-all-countries-all-countries-items';
import { IAllLevelsAllLevelsItems } from '../models/i-all-levels-all-levels-items';
import { IAllSkillsAllSkillsItems } from '../models/i-all-skills-all-skills-items';
import { ICostCentersAllCostCentersItems } from '../models/i-cost-centers-all-cost-centers-items';
import { IEmployeesAllEmployeesItems } from '../models/i-employees-all-employees-items';
import { IGuildsAllGuildsItems } from '../models/i-guilds-all-guilds-items';
import { IProjectsAllProjectsItems } from '../models/i-projects-all-projects-items';
import { IRegionsAllGeographicalRegionsItems } from '../models/i-regions-all-geographical-regions-items';
import { ISkillSetsAllSkillSetsItems } from '../models/i-skill-sets-all-skill-sets-items';
import { ITimeZonesAllTimeZonesItems } from '../models/i-time-zones-all-time-zones-items';
import { IVendorsAllVendorsItems } from '../models/i-vendors-all-vendors-items';

@Injectable({ providedIn: 'root' })
export class ImpulseService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `impulseCountriesGet()` */
  static readonly ImpulseCountriesGetPath = '/impulse/countries';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `impulseCountriesGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  impulseCountriesGet$Plain$Response(
    params?: {
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<IAllCountriesAllCountriesItems>>> {
    const rb = new RequestBuilder(this.rootUrl, ImpulseService.ImpulseCountriesGetPath, 'get');
    if (params) {
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: 'text/plain', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<IAllCountriesAllCountriesItems>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `impulseCountriesGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  impulseCountriesGet$Plain(
    params?: {
    },
    context?: HttpContext
  ): Observable<Array<IAllCountriesAllCountriesItems>> {
    return this.impulseCountriesGet$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<IAllCountriesAllCountriesItems>>): Array<IAllCountriesAllCountriesItems> => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `impulseCountriesGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  impulseCountriesGet$Json$Response(
    params?: {
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<IAllCountriesAllCountriesItems>>> {
    const rb = new RequestBuilder(this.rootUrl, ImpulseService.ImpulseCountriesGetPath, 'get');
    if (params) {
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'text/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<IAllCountriesAllCountriesItems>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `impulseCountriesGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  impulseCountriesGet$Json(
    params?: {
    },
    context?: HttpContext
  ): Observable<Array<IAllCountriesAllCountriesItems>> {
    return this.impulseCountriesGet$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<IAllCountriesAllCountriesItems>>): Array<IAllCountriesAllCountriesItems> => r.body)
    );
  }

  /** Path part for operation `impulseRegionsGet()` */
  static readonly ImpulseRegionsGetPath = '/impulse/regions';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `impulseRegionsGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  impulseRegionsGet$Plain$Response(
    params?: {
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<IRegionsAllGeographicalRegionsItems>>> {
    const rb = new RequestBuilder(this.rootUrl, ImpulseService.ImpulseRegionsGetPath, 'get');
    if (params) {
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: 'text/plain', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<IRegionsAllGeographicalRegionsItems>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `impulseRegionsGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  impulseRegionsGet$Plain(
    params?: {
    },
    context?: HttpContext
  ): Observable<Array<IRegionsAllGeographicalRegionsItems>> {
    return this.impulseRegionsGet$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<IRegionsAllGeographicalRegionsItems>>): Array<IRegionsAllGeographicalRegionsItems> => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `impulseRegionsGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  impulseRegionsGet$Json$Response(
    params?: {
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<IRegionsAllGeographicalRegionsItems>>> {
    const rb = new RequestBuilder(this.rootUrl, ImpulseService.ImpulseRegionsGetPath, 'get');
    if (params) {
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'text/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<IRegionsAllGeographicalRegionsItems>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `impulseRegionsGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  impulseRegionsGet$Json(
    params?: {
    },
    context?: HttpContext
  ): Observable<Array<IRegionsAllGeographicalRegionsItems>> {
    return this.impulseRegionsGet$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<IRegionsAllGeographicalRegionsItems>>): Array<IRegionsAllGeographicalRegionsItems> => r.body)
    );
  }

  /** Path part for operation `impulseTimeZonesGet()` */
  static readonly ImpulseTimeZonesGetPath = '/impulse/time-zones';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `impulseTimeZonesGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  impulseTimeZonesGet$Plain$Response(
    params?: {
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<ITimeZonesAllTimeZonesItems>>> {
    const rb = new RequestBuilder(this.rootUrl, ImpulseService.ImpulseTimeZonesGetPath, 'get');
    if (params) {
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: 'text/plain', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<ITimeZonesAllTimeZonesItems>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `impulseTimeZonesGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  impulseTimeZonesGet$Plain(
    params?: {
    },
    context?: HttpContext
  ): Observable<Array<ITimeZonesAllTimeZonesItems>> {
    return this.impulseTimeZonesGet$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<ITimeZonesAllTimeZonesItems>>): Array<ITimeZonesAllTimeZonesItems> => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `impulseTimeZonesGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  impulseTimeZonesGet$Json$Response(
    params?: {
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<ITimeZonesAllTimeZonesItems>>> {
    const rb = new RequestBuilder(this.rootUrl, ImpulseService.ImpulseTimeZonesGetPath, 'get');
    if (params) {
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'text/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<ITimeZonesAllTimeZonesItems>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `impulseTimeZonesGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  impulseTimeZonesGet$Json(
    params?: {
    },
    context?: HttpContext
  ): Observable<Array<ITimeZonesAllTimeZonesItems>> {
    return this.impulseTimeZonesGet$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<ITimeZonesAllTimeZonesItems>>): Array<ITimeZonesAllTimeZonesItems> => r.body)
    );
  }

  /** Path part for operation `impulseSkillsGet()` */
  static readonly ImpulseSkillsGetPath = '/impulse/skills';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `impulseSkillsGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  impulseSkillsGet$Plain$Response(
    params?: {
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<IAllSkillsAllSkillsItems>>> {
    const rb = new RequestBuilder(this.rootUrl, ImpulseService.ImpulseSkillsGetPath, 'get');
    if (params) {
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: 'text/plain', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<IAllSkillsAllSkillsItems>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `impulseSkillsGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  impulseSkillsGet$Plain(
    params?: {
    },
    context?: HttpContext
  ): Observable<Array<IAllSkillsAllSkillsItems>> {
    return this.impulseSkillsGet$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<IAllSkillsAllSkillsItems>>): Array<IAllSkillsAllSkillsItems> => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `impulseSkillsGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  impulseSkillsGet$Json$Response(
    params?: {
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<IAllSkillsAllSkillsItems>>> {
    const rb = new RequestBuilder(this.rootUrl, ImpulseService.ImpulseSkillsGetPath, 'get');
    if (params) {
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'text/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<IAllSkillsAllSkillsItems>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `impulseSkillsGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  impulseSkillsGet$Json(
    params?: {
    },
    context?: HttpContext
  ): Observable<Array<IAllSkillsAllSkillsItems>> {
    return this.impulseSkillsGet$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<IAllSkillsAllSkillsItems>>): Array<IAllSkillsAllSkillsItems> => r.body)
    );
  }

  /** Path part for operation `impulseSkillSetsGet()` */
  static readonly ImpulseSkillSetsGetPath = '/impulse/skill-sets';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `impulseSkillSetsGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  impulseSkillSetsGet$Plain$Response(
    params?: {
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<ISkillSetsAllSkillSetsItems>>> {
    const rb = new RequestBuilder(this.rootUrl, ImpulseService.ImpulseSkillSetsGetPath, 'get');
    if (params) {
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: 'text/plain', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<ISkillSetsAllSkillSetsItems>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `impulseSkillSetsGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  impulseSkillSetsGet$Plain(
    params?: {
    },
    context?: HttpContext
  ): Observable<Array<ISkillSetsAllSkillSetsItems>> {
    return this.impulseSkillSetsGet$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<ISkillSetsAllSkillSetsItems>>): Array<ISkillSetsAllSkillSetsItems> => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `impulseSkillSetsGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  impulseSkillSetsGet$Json$Response(
    params?: {
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<ISkillSetsAllSkillSetsItems>>> {
    const rb = new RequestBuilder(this.rootUrl, ImpulseService.ImpulseSkillSetsGetPath, 'get');
    if (params) {
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'text/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<ISkillSetsAllSkillSetsItems>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `impulseSkillSetsGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  impulseSkillSetsGet$Json(
    params?: {
    },
    context?: HttpContext
  ): Observable<Array<ISkillSetsAllSkillSetsItems>> {
    return this.impulseSkillSetsGet$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<ISkillSetsAllSkillSetsItems>>): Array<ISkillSetsAllSkillSetsItems> => r.body)
    );
  }

  /** Path part for operation `impulseLevelsGet()` */
  static readonly ImpulseLevelsGetPath = '/impulse/levels';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `impulseLevelsGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  impulseLevelsGet$Plain$Response(
    params?: {
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<IAllLevelsAllLevelsItems>>> {
    const rb = new RequestBuilder(this.rootUrl, ImpulseService.ImpulseLevelsGetPath, 'get');
    if (params) {
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: 'text/plain', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<IAllLevelsAllLevelsItems>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `impulseLevelsGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  impulseLevelsGet$Plain(
    params?: {
    },
    context?: HttpContext
  ): Observable<Array<IAllLevelsAllLevelsItems>> {
    return this.impulseLevelsGet$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<IAllLevelsAllLevelsItems>>): Array<IAllLevelsAllLevelsItems> => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `impulseLevelsGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  impulseLevelsGet$Json$Response(
    params?: {
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<IAllLevelsAllLevelsItems>>> {
    const rb = new RequestBuilder(this.rootUrl, ImpulseService.ImpulseLevelsGetPath, 'get');
    if (params) {
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'text/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<IAllLevelsAllLevelsItems>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `impulseLevelsGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  impulseLevelsGet$Json(
    params?: {
    },
    context?: HttpContext
  ): Observable<Array<IAllLevelsAllLevelsItems>> {
    return this.impulseLevelsGet$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<IAllLevelsAllLevelsItems>>): Array<IAllLevelsAllLevelsItems> => r.body)
    );
  }

  /** Path part for operation `impulseGuildsGet()` */
  static readonly ImpulseGuildsGetPath = '/impulse/guilds';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `impulseGuildsGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  impulseGuildsGet$Plain$Response(
    params?: {
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<IGuildsAllGuildsItems>>> {
    const rb = new RequestBuilder(this.rootUrl, ImpulseService.ImpulseGuildsGetPath, 'get');
    if (params) {
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: 'text/plain', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<IGuildsAllGuildsItems>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `impulseGuildsGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  impulseGuildsGet$Plain(
    params?: {
    },
    context?: HttpContext
  ): Observable<Array<IGuildsAllGuildsItems>> {
    return this.impulseGuildsGet$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<IGuildsAllGuildsItems>>): Array<IGuildsAllGuildsItems> => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `impulseGuildsGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  impulseGuildsGet$Json$Response(
    params?: {
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<IGuildsAllGuildsItems>>> {
    const rb = new RequestBuilder(this.rootUrl, ImpulseService.ImpulseGuildsGetPath, 'get');
    if (params) {
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'text/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<IGuildsAllGuildsItems>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `impulseGuildsGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  impulseGuildsGet$Json(
    params?: {
    },
    context?: HttpContext
  ): Observable<Array<IGuildsAllGuildsItems>> {
    return this.impulseGuildsGet$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<IGuildsAllGuildsItems>>): Array<IGuildsAllGuildsItems> => r.body)
    );
  }

  /** Path part for operation `impulseCostCentersGet()` */
  static readonly ImpulseCostCentersGetPath = '/impulse/cost-centers';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `impulseCostCentersGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  impulseCostCentersGet$Plain$Response(
    params?: {
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<ICostCentersAllCostCentersItems>>> {
    const rb = new RequestBuilder(this.rootUrl, ImpulseService.ImpulseCostCentersGetPath, 'get');
    if (params) {
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: 'text/plain', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<ICostCentersAllCostCentersItems>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `impulseCostCentersGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  impulseCostCentersGet$Plain(
    params?: {
    },
    context?: HttpContext
  ): Observable<Array<ICostCentersAllCostCentersItems>> {
    return this.impulseCostCentersGet$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<ICostCentersAllCostCentersItems>>): Array<ICostCentersAllCostCentersItems> => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `impulseCostCentersGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  impulseCostCentersGet$Json$Response(
    params?: {
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<ICostCentersAllCostCentersItems>>> {
    const rb = new RequestBuilder(this.rootUrl, ImpulseService.ImpulseCostCentersGetPath, 'get');
    if (params) {
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'text/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<ICostCentersAllCostCentersItems>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `impulseCostCentersGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  impulseCostCentersGet$Json(
    params?: {
    },
    context?: HttpContext
  ): Observable<Array<ICostCentersAllCostCentersItems>> {
    return this.impulseCostCentersGet$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<ICostCentersAllCostCentersItems>>): Array<ICostCentersAllCostCentersItems> => r.body)
    );
  }

  /** Path part for operation `impulseVendorsGet()` */
  static readonly ImpulseVendorsGetPath = '/impulse/vendors';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `impulseVendorsGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  impulseVendorsGet$Plain$Response(
    params?: {
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<IVendorsAllVendorsItems>>> {
    const rb = new RequestBuilder(this.rootUrl, ImpulseService.ImpulseVendorsGetPath, 'get');
    if (params) {
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: 'text/plain', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<IVendorsAllVendorsItems>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `impulseVendorsGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  impulseVendorsGet$Plain(
    params?: {
    },
    context?: HttpContext
  ): Observable<Array<IVendorsAllVendorsItems>> {
    return this.impulseVendorsGet$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<IVendorsAllVendorsItems>>): Array<IVendorsAllVendorsItems> => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `impulseVendorsGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  impulseVendorsGet$Json$Response(
    params?: {
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<IVendorsAllVendorsItems>>> {
    const rb = new RequestBuilder(this.rootUrl, ImpulseService.ImpulseVendorsGetPath, 'get');
    if (params) {
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'text/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<IVendorsAllVendorsItems>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `impulseVendorsGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  impulseVendorsGet$Json(
    params?: {
    },
    context?: HttpContext
  ): Observable<Array<IVendorsAllVendorsItems>> {
    return this.impulseVendorsGet$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<IVendorsAllVendorsItems>>): Array<IVendorsAllVendorsItems> => r.body)
    );
  }

  /** Path part for operation `impulseAccountsGet()` */
  static readonly ImpulseAccountsGetPath = '/impulse/accounts';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `impulseAccountsGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  impulseAccountsGet$Plain$Response(
    params?: {
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<IAccountsAllAccountsItems>>> {
    const rb = new RequestBuilder(this.rootUrl, ImpulseService.ImpulseAccountsGetPath, 'get');
    if (params) {
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: 'text/plain', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<IAccountsAllAccountsItems>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `impulseAccountsGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  impulseAccountsGet$Plain(
    params?: {
    },
    context?: HttpContext
  ): Observable<Array<IAccountsAllAccountsItems>> {
    return this.impulseAccountsGet$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<IAccountsAllAccountsItems>>): Array<IAccountsAllAccountsItems> => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `impulseAccountsGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  impulseAccountsGet$Json$Response(
    params?: {
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<IAccountsAllAccountsItems>>> {
    const rb = new RequestBuilder(this.rootUrl, ImpulseService.ImpulseAccountsGetPath, 'get');
    if (params) {
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'text/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<IAccountsAllAccountsItems>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `impulseAccountsGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  impulseAccountsGet$Json(
    params?: {
    },
    context?: HttpContext
  ): Observable<Array<IAccountsAllAccountsItems>> {
    return this.impulseAccountsGet$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<IAccountsAllAccountsItems>>): Array<IAccountsAllAccountsItems> => r.body)
    );
  }

  /** Path part for operation `impulseEmployeesGet()` */
  static readonly ImpulseEmployeesGetPath = '/impulse/employees';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `impulseEmployeesGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  impulseEmployeesGet$Plain$Response(
    params?: {
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<IEmployeesAllEmployeesItems>>> {
    const rb = new RequestBuilder(this.rootUrl, ImpulseService.ImpulseEmployeesGetPath, 'get');
    if (params) {
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: 'text/plain', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<IEmployeesAllEmployeesItems>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `impulseEmployeesGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  impulseEmployeesGet$Plain(
    params?: {
    },
    context?: HttpContext
  ): Observable<Array<IEmployeesAllEmployeesItems>> {
    return this.impulseEmployeesGet$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<IEmployeesAllEmployeesItems>>): Array<IEmployeesAllEmployeesItems> => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `impulseEmployeesGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  impulseEmployeesGet$Json$Response(
    params?: {
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<IEmployeesAllEmployeesItems>>> {
    const rb = new RequestBuilder(this.rootUrl, ImpulseService.ImpulseEmployeesGetPath, 'get');
    if (params) {
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'text/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<IEmployeesAllEmployeesItems>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `impulseEmployeesGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  impulseEmployeesGet$Json(
    params?: {
    },
    context?: HttpContext
  ): Observable<Array<IEmployeesAllEmployeesItems>> {
    return this.impulseEmployeesGet$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<IEmployeesAllEmployeesItems>>): Array<IEmployeesAllEmployeesItems> => r.body)
    );
  }

  /** Path part for operation `impulseProjectsGet()` */
  static readonly ImpulseProjectsGetPath = '/impulse/projects';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `impulseProjectsGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  impulseProjectsGet$Plain$Response(
    params?: {
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<IProjectsAllProjectsItems>>> {
    const rb = new RequestBuilder(this.rootUrl, ImpulseService.ImpulseProjectsGetPath, 'get');
    if (params) {
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: 'text/plain', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<IProjectsAllProjectsItems>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `impulseProjectsGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  impulseProjectsGet$Plain(
    params?: {
    },
    context?: HttpContext
  ): Observable<Array<IProjectsAllProjectsItems>> {
    return this.impulseProjectsGet$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<IProjectsAllProjectsItems>>): Array<IProjectsAllProjectsItems> => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `impulseProjectsGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  impulseProjectsGet$Json$Response(
    params?: {
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<IProjectsAllProjectsItems>>> {
    const rb = new RequestBuilder(this.rootUrl, ImpulseService.ImpulseProjectsGetPath, 'get');
    if (params) {
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'text/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<IProjectsAllProjectsItems>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `impulseProjectsGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  impulseProjectsGet$Json(
    params?: {
    },
    context?: HttpContext
  ): Observable<Array<IProjectsAllProjectsItems>> {
    return this.impulseProjectsGet$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<IProjectsAllProjectsItems>>): Array<IProjectsAllProjectsItems> => r.body)
    );
  }

}
